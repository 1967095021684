<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <div class="card-body">
          <router-link
            class="btn btn-sm btn-dark  "
            size="sm"
            :to="{ path: '/user-admin' }"
          >
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">Edit User Admin</h3>
              <div class="form-group">
                <label for="name"
                  >Email<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="type email.."
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <label for="role"
                  >Role<span class="text-danger">*</span> :</label
                >
                <multiselect
                  v-model="role"
                  label="name"
                  track-by="name"
                  placeholder="type to find role"
                  open-direction="bottom"
                  :options="optionsRole"
                  :searchable="true"
                  :loading="isLoadingSearch"
                  :close-on-select="true"
                  @input="changeGroup"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="form-group">
                <label for="status"
                  >Group<span class="text-danger">*</span> :</label
                >
                <b-form-select
                  class="form-control"
                  id="group"
                  v-model="group"
                  :options="optionsGroup"
                  :disabled="groupDisabled"
                >
                </b-form-select>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="is_active"
                  v-model="isActive"
                />
                <label class="form-check-label" for="is_active"
                  >Is Active</label
                >
              </div>
              <button
                type="submit"
                class="btn btn-block btn-primary mt-5"
                @click="editUserAdmin"
              >
                <i class="fa fa-pencil"></i>
                Update User Admin
              </button>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
export default {
  name: 'CreateUserAdmin',
  data() {
    return {
      email: '',
      isLoading: false,
      isLoadingSearch: false,
      role: [],
      optionsRole: [],
      group: 'ebook',
      optionsGroup: [
        { value: 'ebook', text: 'Ebook' },
        { value: 'publisher', text: 'Publisher' },
      ],
      isActive: true,
      groupDisabled: null,
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.admin.item,
      successMessage: (state) => state.admin.successMessage,
      errorMessage: (state) => state.admin.errorMessage,
      isError: (state) => state.admin.isError,
    }),
    buttonLabel() {
      return this.showPassword ? 'Show' : 'Hide';
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Update User Admin | Content Management System Ebooks Gramedia.com';
      },
    },
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getUserAdmin();
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/user-admin');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
  },
  created: function() {
    const userID = this.$route.params.id;
    this.fetchUserAdminById({ id: userID });
    this.getUserAdmin();
  },
  mounted() {
    this.fetchRole();
  },
  methods: {
    ...mapActions('admin', ['updateUserAdmin', 'fetchUserAdminById']),
    ...mapActions('accessRole', ['fetchAllRole']),

    fetchRole() {
      this.fetchAllRole().then((res) => {
        for (let item in res) {
          this.optionsRole.push(res[item]);
        }
      });
    },

    async getUserAdmin() {
      const data = this.item;
      if (data.group == 'publisher') {
        this.groupDisabled = true;
      } else {
        this.groupDisabled = false;
      }
      this.email = data.email;
      this.role = data.roles;
      this.group = data.group;
      this.isActive = data.is_active;
    },

    async editUserAdmin() {
      const userID = this.$route.params.id;
      const payload = {
        id: userID,
        email: this.email,
        role_id: this.role.id,
        group: this.group,
        is_active: this.isActive ?? false,
      };
      this.updateUserAdmin(payload);
    },

    changeGroup() {
      if (constant.ALLOWED_ROLE_WITHOUT_VENDOR.includes(this.role.id)) {
        this.group = 'ebook';
      } else {
        this.group = 'publisher';
      }
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
